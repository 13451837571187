import React, {useContext} from "react";
import "./Contact.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import {illustration, contactInfo} from "../../portfolio";
import {Fade} from "react-reveal";
import email from "../../assets/lottie/email";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";

export default function Contact() {
  const {isDark} = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">{contactInfo.title}</h1>
            <p
              className={
                isDark
                  ? "darkModeTextSec contact-subtitle"
                  : "card-subtitle contact-subtitle"
              }
            >
              {contactInfo.subtitle}
            </p>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.number && (
                <a
                  className="contact-detail-link"
                  href={"tel:" + contactInfo.number}
                >
                  <i class="fa fa-phone"></i>
                  {contactInfo.number}
                </a>
              )}
            </div>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.email_address && (
                <a
                  className="contact-detail-link"
                  href={"mailto:" + contactInfo.email_address}
                >
                  <i class="fa fa-envelope"></i>
                  {contactInfo.email_address}
                </a>
              )}
            </div>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.location && (
                <a className="contact-detail-link" href="#">
                  <i class="fas fa-location-dot"></i>
                  {contactInfo.location}
                </a>
              )}
            </div>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.openForOpportunities && (
                <a
                  className="contact-detail-link"
                  href={"mailto:" + contactInfo.email_address}
                >
                  <i class="fa-solid fa-globe"></i>
                  Open for opportunities
                </a>
              )}
            </div>
            <div className="contact-social-media">
              <SocialMedia />
            </div>
          </div>
          <div className="contact-image-div">
            <DisplayLottie animationData={email} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
