import React, {useState, useContext} from "react";
import StyleContext from "../../contexts/StyleContext";
import DayNightSwtich from "../dayNightSwitch";

const ToggleSwitch = () => {
  const {isDark} = useContext(StyleContext);
  const [isChecked, setChecked] = useState(isDark);
  const styleContext = useContext(StyleContext);

  return (
    <DayNightSwtich
      checked={isDark}
      onChange={() => {
        styleContext.changeTheme();
        setChecked(!isChecked);
      }}
    />
  );
};
export default ToggleSwitch;
