import React, {useCallback, useContext, useState} from "react";
import "./StartupProjects.scss";
import {bigProjects} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import Carousel from "../../components/carousel";
import Button from "../../components/button/Button";
import {useWindowDim} from "../../hooks/useWindowDim";

export default function StartupProject() {
  const isSmall = useWindowDim().width <= 928;
  const batchSize = isSmall ? 4 : 6;
  const [cursor, setCursor] = useState(batchSize);

  const onShowMore = useCallback(() => {
    setCursor(cursor => cursor + batchSize);
  }, [batchSize]);

  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }

  const {isDark} = useContext(StyleContext);
  if (!bigProjects.display) {
    return null;
  }
  return (
    <div className="main" id="projects">
      <div>
        <h1 className="skills-heading">{bigProjects.title}</h1>
        <p
          className={
            isDark
              ? "darkModeTextSec project-subtitle"
              : "subTitle project-subtitle"
          }
        >
          {bigProjects.subtitle}
        </p>

        <div className="projects-container">
          {bigProjects.projects.slice(0, cursor).map((project, i) => {
            return (
              <Fade key={i} bottom duration={1000} distance="20px">
                <div
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.images ? (
                    <div>
                      <Carousel images={project.images} />
                    </div>
                  ) : null}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <p
                      className={isDark ? "darkModeTextSec" : "card-subtitle"}
                      dangerouslySetInnerHTML={{__html: project.projectDesc}}
                    />
                    <ul style={{textAlign: "left"}}>
                      {project.descBullets
                        ? project.descBullets.map((item, i) => (
                            <li
                              key={i}
                              className={
                                isDark ? "darkModeTextSec" : "card-subtitle"
                              }
                            >
                              {item}
                            </li>
                          ))
                        : null}
                    </ul>
                    {project.footerLink ? (
                      <div className="project-card-footer">
                        {project.footerLink.map((link, i) => {
                          return (
                            <span
                              key={i}
                              className={
                                isDark ? "dark-mode project-tag" : "project-tag"
                              }
                              onClick={() => openUrlInNewTab(link.url)}
                            >
                              {link.name}
                            </span>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
        {cursor < bigProjects.projects.length ? (
          <Button
            onClick={onShowMore}
            text="Show More"
            className="project-button"
          />
        ) : null}
      </div>
    </div>
  );
}
