import React from "react";
import "./index.scss";

function DayNightSwtich({checked, onChange}) {
  return (
    <div class="toggleWrapper">
      <input
        checked={checked}
        onChange={onChange}
        type="checkbox"
        class="dn"
        id="dn"
      />
      <label for="dn" class="toggle">
        <span class="toggle__handler">
          <span class="crater crater--1"></span>
          <span class="crater crater--2"></span>
          <span class="crater crater--3"></span>
        </span>
        <span class="star star--1"></span>
        <span class="star star--2"></span>
        <span class="star star--3"></span>
        <span class="star star--4"></span>
        <span class="star star--5"></span>
        <span class="star star--6"></span>
      </label>
    </div>
  );
}

export default DayNightSwtich;
