import React from "react";
import "./Button.scss";

export default function Button({text, onClick, className, href, newTab}) {
  return (
    <div className={className}>
      <a
        className="main-button"
        onClick={onClick}
        href={href}
        target={newTab && "_blank"}
      >
        {text}
      </a>
    </div>
  );
}
