/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation
import {emoji} from "./utils";

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Maaz Bin Khawar",
  title: "Hello, it's Maaz",
  subTitle: emoji(
    `A passionate fullstack engineer with 5+ years of professional experience in building and scaling web and mobile applications from scratch. A problem solver at heart ❤️`
  ),
  resumeLink:
    "https://drive.google.com/file/d/1-1gZUCXpKdE5phJFCCXHRAi5h-Bsydpw/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/MohammedMaaz",
  linkedin: "https://www.linkedin.com/in/maaz-bin-khawar-6290b0116/",
  // gitlab: "https://gitlab.com/MohammedMaaz",
  stackoverflow: "https://stackoverflow.com/users/5992689/maaz-bin-khawar",
  display: true
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "LOVE SOLVING PROBLEMS AND DELIGHTING CUSTOMERS",
  skills: [
    emoji(
      "⚡ Build functionally rich frontends for SCSS, PWAs, Websites and Mobile Apps"
    ),
    emoji(
      "⚡ Design, architect and implement highly scalable backends (mainly on GCP)"
    ),
    emoji(
      "⚡ Learning cool technologies and industry best practices while sharing them with my team"
    ),
    emoji(
      "⚡ Optimizing and refactoring old code while addressing technical debts"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "Typescript",
      fontAwesomeClassname: "fab fa-t"
    },
    {
      skillName: "ReactJs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "NextJS",
      fontAwesomeClassname: "fab fa-n"
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "fas fa-leaf"
    },
    {
      skillName: "Google Cloud",
      fontAwesomeClassname: "fab fa-google"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "NED University",
      logo: require("./assets/images/ned_logo.png"),
      subHeader: "Bachelors of Electrical Engineering",
      duration: "December 2015 - July 2019",
      descBullets: [
        "A+ grade (94% or above marks) in Computers and Programming (EE-163), Data Structures and Algorithms (EE-264)",
        "Gestures Controlled Humanoid Robot (NAO v5) with Virtual Reality Visualization using Microsoft Kinect, Oculus Rift and C# and python for firmware programming and communication",
        "Built a Scientific Calculator GUI based Desktop App in C++ using SFML library",
        "PID based speed controller for a BLDC motor using AtMega328 microcontroller written in C"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend Web (React)",
      progressPercentage: "95%"
    },
    {
      Stack: "Backend (NodeJs)",
      progressPercentage: "80%"
    },
    {
      Stack: "Mobile Apps (React Native)",
      progressPercentage: "70%"
    },
    {
      Stack: "Cloud (GCP)",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Software Engineer - Freelance (remote)",
      company: "Agenta AI",
      companylogo: require("./assets/images/agenta.jpg"),
      date: "Aug 2023 – May 2024",
      descBullets: [
        "Led the revamp of  platform’s frontend architecture using NextJS and TS, optimizing for scalability, security, and developer experience by incorporating best practices and optimization techniques like memoization",
        "Spearheaded development of prompt evaluations and observability, implementing custom tabular views with real-time status tracking and dynamic comparison capabilities",
        "Innovated component and dependency loading strategy in a dynamic manner, enhancing performance and security of Agenta's closed-source SaaS features"
      ]
    },
    {
      role: "Senior Software Engineer (remote)",
      company: "Axya",
      companylogo: require("./assets/images/axya.jpeg"),
      date: "Feb 2022 – Present",
      descBullets: [
        "Led the development of a searchable supplier portfolio on NextJS, boosting supplier visibility by 1.7x, resulting in an increase in monthly subscriptions and achievement of quarterly OKR",
        "Revamped RFQ creation form from a multi-step process to a single-step intuitive drawer by working closely with the UI/UX team, resulting in an average time savings of 1.5 minutes and elimination of UX pain points",
        "Established the foundation for e2e testing in a React app by integrating Cypress and documenting best practices, improving DX and reducing average defect rate by 23% in production"
      ]
    },
    {
      role: "Software Engineer",
      company: "10Pearls",
      companylogo: require("./assets/images/10pearls.jpeg"),
      date: "Feb 2021 – Feb 2022",
      descBullets: [
        "Reduced API calls by 91% used to poll server state for a high traffic React control panel by employing an efficient caching mechanism",
        "Conducted static and dynamic code analysis on a 100,000+ line firmware codebase for an enterprise, resulting in FIPS 140-2 compliance through identification and replacement of non-compliant cryptography algorithms",
        "Delivered a POC on many-to-many video calling automation for a telehealth application using apple script and OBS Studio, saving 20 USD/machine by avoiding previously used hardware-based solution"
      ]
    },
    {
      role: "Full Stack Engineer",
      company: "Atompoint",
      companylogo: require("./assets/images/atompoint.jpeg"),
      date: "Dec 2019 – Jan 2021",
      descBullets: [
        "Architected GCP infrastructure and led a team of 3 developers to build an Uber-like water delivery app in React Native with recursive order broadcasting, real-time delivery tracking, and in-app wallet features",
        "Migrated an enterprise portfolio website from React SPA to NextJS JAM stack using Contentful APIs, improving FCP by 58% and enhancing SEO performance through configurable meta tags",
        "Secured compliance for industrial monitoring SaaS company through implementation of automated backup/restore of database and storage services on GCP, and introduction of RBAC layer using security rules"
      ]
    },
    {
      role: "Fullstack Engineer (Mobile)",
      company: "Denovers",
      companylogo: require("./assets/images/Denovers.jpeg"),
      date: "Mar 2019 – Jan 2020",
      descBullets: [
        "Built frontend of a React based docs analyzer SaaS, employing a WYSIWYG editor based on Draft.js, a document parser and a Google Docs styled document index generator based of an O(N) time algorithm",
        "Collaborated closely with stake holders, UI/UX designer and fellow developer to build MVP of a location-driven marketplace on React Native and NodeJS, having full-text search, real-time chat and rating based rankings"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: false, // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Portfolio Projects",
  subtitle: "SOME OF THE MILESTONE PROJECTS OF MY CAREER",
  projects: [
    {
      images: [
        require("./assets/images/Senseii Games/1.png"),
        require("./assets/images/Senseii Games/2.png"),
        require("./assets/images/Senseii Games/3.png"),
        require("./assets/images/Senseii Games/4.png"),
        require("./assets/images/Senseii Games/5.png")
      ],
      projectName: "Senseii Games",
      projectDesc:
        "A low-code platform for kids to teach coding through game development platform for kids\n\n<strong>Tech Stack: ReactJS, Typescript, Tailwind CSS, Blockly, Jotai, Konva, Supabase</strong>\n\n<strong>Features: </strong>Low code drag and drop game development through google's blockly library, Gameplay simulation, Javascript code output, Levels progress tracking, Dark mode and mobile first UI",
      descBullets: [
        "RnD on google's blockly library integration with react for low code editor",
        "Architected frontend of the app to use a single json config for generating game levels and their respective finish conditions",
        "Used konva with react to create an SVG based game engine to simulate gameplays based on generated Javascript code",
        "Implemented progress tracking and authentication system using Supabase and Jotai"
      ],
      footerLink: [
        {
          name: "Open App",
          url: "https://www.senseiigames.com/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Crehab/crehab-1.png"),
        require("./assets/images/Crehab/crehab-2.png"),
        require("./assets/images/Crehab/crehab-3.png"),
        require("./assets/images/Crehab/crehab-4.png"),
        require("./assets/images/Crehab/crehab-5.png")
      ],
      projectName: "EMR Web App",
      projectDesc:
        "An EMR (Electronic Medical Records) management PWA.\n\n<strong>Tech Stack: </strong>ReactJS, Redux, NodeJS, Firebase/GCP, SCSS, Ant Design\n\n<strong>Features:</strong> Patient management, RBAC (admins, clinicians, nurses), Google docs style rich-text medical notes editor, whatsapp-like realtime integrated chat, responsive and installable PWA, email and push notifications",
      descBullets: [
        "Strategized and architected backend of the app on Firebase and various GCP services",
        "Laid the foundation of frontend scaffolding of the app having theming and localization structure embedded for enhanced developer experience",
        "Implemented whatsapp-like realtime chat feature with support for uploading attachments and patient's activity logs from scratch",
        "Contributed in the core frontend development converting Figma mockups to functional and responsive React components"
      ],
      footerLink: [
        {
          name: "Open App",
          url: "https://emr.crehabconsultants.com/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Waterlink/unnamed.webp"),
        require("./assets/images/Waterlink/unnamed (1).webp"),
        require("./assets/images/Waterlink/unnamed (2).webp"),
        require("./assets/images/Waterlink/unnamed (3).webp"),
        require("./assets/images/Waterlink/unnamed (4).webp")
      ],
      projectName: "Waterlink",
      projectDesc:
        "An uber-like water tanker delivery service.\n\n<strong>Tech Stack:</strong> React Native, Redux(saga), Firebase/GCP, Google Maps, NodeJS, DialogFlow\n\n<strong>Features:</strong> Instant Booking, Scheduled Booking, Real-time Delivery Tracking, In App Wallet, Orders and Transactions History, App Invites and Referral Bonus",
      descBullets: [
        "Technically led a team of 3 engineers, translating user stories into implementation details as well as creating app's foundational scaffoldings for the team",
        "Wrote complete backend of the application in NodeJS utilizing GCP and Firebase services to implement features such as recursive order broadcasting, in-app wallet, and orders scheduling",
        "Wrote a chat bot on Dialogflow to enable customers book orders through Whatsapp, resulting in improved customer experience"
      ],
      footerLink: [
        {
          name: "Download Mobile App",
          url: "https://play.google.com/store/apps/details?id=com.waterlink"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Traffic Digital/About.png"),
        require("./assets/images/Traffic Digital/unicef.png"),
        require("./assets/images/Traffic Digital/menu.png"),
        require("./assets/images/Traffic Digital/Screenshot 2022-07-09 at 10.29.20 PM.png")
      ],
      projectName: "Traffic Digital",
      projectDesc:
        '"We Want Traffic" portfolio website built on JAM stack.\n\n<strong>Tech Stack:</strong> NextJS, Contentful, SCSS, Framer Motion\n\n<strong>Features:</strong> SSR and SSG for speedy loading, Multi Language and RTL support, Eye-catching animations',
      descBullets: [
        "Architected frontend of the website on NextJS",
        "Did data modelling of the website's static content on contentful for seamless frontend integration and dynamic configurability of content",
        "Created initial frontend scaffolding of the website for the dev team to build on top of it by using it as a base structure"
      ],
      footerLink: [
        {
          name: "Open Website",
          url: "https://www.wewanttraffic.com/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/VectorLink/home.png"),
        require("./assets/images/VectorLink/pricing.png"),
        require("./assets/images/VectorLink/blog.png"),
        require("./assets/images/VectorLink/blog-detail.png"),
        require("./assets/images/VectorLink/features.png"),
        require("./assets/images/VectorLink/platform.png")
      ],
      projectName: "VectorLink",
      projectDesc:
        "A SaaS based cross platform VPN service website.\n\n<strong>Tech Stack:</strong> NextJS, Contentful, i18n, SCSS, rich-text\n\n<strong>Features:</strong> Dynamic content management via Contentful, Multi Lingual, In app blog with search and tags, SEO friendly",
      descBullets: [
        "Modelled the website's content on contentful for dynamic configurability",
        "Developed an in-app blog with search, tags and 'more like this' functionality",
        "Converted Figma mockups to functional and responsive React components"
      ],
      footerLink: [
        {
          name: "Open Website",
          url: "https://vectorlink.io/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Vanuse/home.png"),
        require("./assets/images/Vanuse/home-2.png"),
        require("./assets/images/Vanuse/home-3.png"),
        require("./assets/images/Vanuse/drivers.png")
      ],
      projectName: "Vanuse",
      projectDesc:
        "An on demand Van booking web app.\n\n<strong>Tech Stack:</strong> ReactJS (NextJs), Redux, Material UI, SCSS, Storybook\n\n<strong>Features</strong>: SEO optimized and mobile responsive through NextJS, Customize arrival location through Mapbox, Payment gateway integration through Stripe",
      descBullets: [
        "Developed multi page branding website on NextJS with mobile and tablet responsiveness",
        "Troubleshoot and fixed functional edge cases and performance bottlenecks in the booking web app",
        "Refactored code to create reusable abstractions over business logics and addressed security malpractices in authentication flow"
      ],
      footerLink: [
        {
          name: "Open App",
          url: "https://vanuse.com/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/chroma/doc-editor.png"),
        require("./assets/images/chroma/home.png"),
        require("./assets/images/chroma/contracts.png"),
        require("./assets/images/chroma/accounts.png")
      ],
      projectName: "Chroma",
      projectDesc:
        "An AI powered document editor and contract analyzer SaaS tool for lawyers\n\n<strong>Tech Stack:</strong> ReactJS, DraftJS, CSS\n\n<strong>Features</strong>: Google Docs styled Draft.js based document editor, AI powered highlighted portions for risk grading, Collaboration through Google Docs styled comments",
      descBullets: [
        "Created Google Docs styled document editor with collaborative comments feature on top of Draf.js",
        "Wrote an algorithm to automatically generate infinite depth document index in O(N) time",
        "Designed authentication flow on frontend using OAuth best practices with auto token refresh, request retry mechanism and logout listeners when multiple tabs are open"
      ],
      footerLink: [
        {
          name: "See Case Study",
          url: "https://denovers.com/ourwork/Conversational-AI-Assistant-casestudy.html"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Mandi Express/home.png"),
        require("./assets/images/Mandi Express/home2.png"),
        require("./assets/images/Mandi Express/category.png"),
        require("./assets/images/Mandi Express/product details.png"),
        require("./assets/images/Mandi Express/shipping details.png"),
        require("./assets/images/Mandi Express/cart.png"),
        require("./assets/images/Mandi Express/home mobile.png"),
        require("./assets/images/Mandi Express/home mobile 2.png"),
        require("./assets/images/Mandi Express/product mobile.png"),
        require("./assets/images/Mandi Express/shipping mobile.png"),
        require("./assets/images/Mandi Express/cart mobile.png"),
        require("./assets/images/Mandi Express/menu mobile.png")
      ],
      projectName: "Mandi Express",
      projectDesc:
        "An online grocery items marketplace built as PWA on JAM stack.\n\n<strong>Tech Stack:</strong> React JS, GatsbyJS, GraphQL, Shopify, Shipday API\n\n<strong>Features:</strong> Blazing Fast - less than 1s FCP time, Installable PWA, Super easy store management through Shopify",
      descBullets: [
        "Wrote a google cloud function to act as an event debouncer for Shopify webhooks, which reduced redundant Gatsby builds by more than 300%",
        "Leaded frontend development and backend integrations of the website by creating an easy to use scaffolding for the team",
        "Did code reviews, performance audits and fixed crucial UI and performance issues"
      ],
      footerLink: [
        {
          name: "Open Website",
          url: "https://mandib2c.vercel.app/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Spodse/dashboard.png"),
        require("./assets/images/Spodse/create new event.png"),
        require("./assets/images/Spodse/event details.png"),
        require("./assets/images/Spodse/Screenshot 2022-06-30 at 11.54.23 AM.png"),
        require("./assets/images/Spodse/my events.png")
      ],
      projectName: "Spodse Control Panel",
      projectDesc:
        "A business/admin panel for an events listing mobile app\n\n<strong>Tech Stack:</strong> ReactJS, Redux (Saga), Sass, Cloud Firestore, NodeJS, GCP, Algolia, Stripe\n\n<strong>Features</strong>: Event Invites via Dynamic Links, Live Event Preview, Configurable theming, Full text search",
      descBullets: [
        "Developed frontend of the panel from scratch using React, Redux Saga and Antdesign, having real-time data visualizations, data lazy-loading and client-side caching features for increased performance and enhanced user experience",
        "Architected and developed backend of the whole Spodse app writing low latency and high resilient serverless google cloud functions in NodeJS",
        "Did data modelling and schema documentation of the database"
      ],
      footerLink: [
        {
          name: "Open Control Panel",
          url: "https://spodse.app/"
        },
        {
          name: "Download Mobile App",
          url: "https://play.google.com/store/apps/details?id=com.tbleAS.spodse&gl=NO"
        }
      ]
    },
    {
      images: [
        require("./assets/images/VectorLinkAdmin/sc1.png"),
        require("./assets/images/VectorLinkAdmin/sc2.png"),
        require("./assets/images/VectorLinkAdmin/sc3.png"),
        require("./assets/images/VectorLinkAdmin/sc4.png"),
        require("./assets/images/VectorLinkAdmin/sc5.png"),
        require("./assets/images/VectorLinkAdmin/sc6.png"),
        require("./assets/images/VectorLinkAdmin/sc8.png"),
        require("./assets/images/VectorLinkAdmin/sc9.png"),
        require("./assets/images/VectorLinkAdmin/sc10.png")
      ],
      projectName: "VectorLink Admin",
      projectDesc:
        "Admin panel of VectorLink - VPN service\n\n<strong>Tech Stack: </strong>ReactJS, Redux, RTK, AntDesign, SCSS\n\n<strong>Features</strong>: Stats Dashboard, Users Management, Payments Management, VPN Servers configuration management, Subscription Plans Management, Gift Cards management for resellers, Payments tracking",
      descBullets: [
        "Wrote a dynamically configurable table component to display data from any api endpoint with support for pagination, sorting, filtering and searching",
        "Integrated Stripe to manage payments and subscriptions",
        "Developed a declarative config based routing system to manage protected and public routes, as well as menu items"
      ],
      footerLink: [
        {
          name: "Open Panel",
          url: "https://admin.vectorlink.io"
        }
      ]
    },
    {
      images: [
        require("./assets/images/Nesalink/sc1.png"),
        require("./assets/images/Nesalink/sc2.png"),
        require("./assets/images/Nesalink/sc3.png"),
        require("./assets/images/Nesalink/sc4.png"),
        require("./assets/images/Nesalink/sc5.png")
      ],
      projectName: "Nesalink",
      projectDesc:
        "A simplistic EHR for NAMER based patients and doctors \n\n<strong>Tech Stack:</strong> ReactJS, React Query, Tailwind CSS, Firebase, Axios\n\n<strong>Features</strong>: Patients' Dashboard, Mobile responsive, Optimistic UI",
      descBullets: [
        "Developed frontend of the panel from scratch",
        "Used tailwind CSS for declarative styling and mobile first UI",
        "Used React Query for data fetching and cache invalidation to optimistically update the UI on mutations"
      ],
      footerLink: [
        {
          name: "Open App",
          url: "https://nesalinkpa.web.app/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/waterlink business/dashboard.png"),
        require("./assets/images/waterlink business/drivers.png"),
        require("./assets/images/waterlink business/add driver.png"),
        require("./assets/images/waterlink business/orders.png"),
        require("./assets/images/waterlink business/payments.png")
      ],
      projectName: "Waterlink Business",
      projectDesc:
        "Control panel for waterlink transporters to manage drivers, orders and payments\n\n<strong>Tech Stack:</strong> ReactJS, Redux (Saga), Ant Design, Firebase\n\n<strong>Features</strong>: Stats dashboard and map based drivers' tracking, Invite drivers using sms based deep links, Orders management, Transactions history and wallet management",
      descBullets: [
        "Leaded as a technical team lead to architect app's infrastructure and codebase while also communicating SDLC practices to the team",
        "Developed initial layout and project's foundation on React using AntDesign's UMI framework",
        "Integrated google maps for drivers' location tracking by creating a utility library on top of google maps javascript SDK"
      ],
      footerLink: [
        {
          name: "Open Control Panel",
          url: "https://business.waterlink.pk/"
        }
      ]
    },
    {
      images: [
        require("./assets/images/waterlink driver/home.png"),
        require("./assets/images/waterlink driver/new order.png"),
        require("./assets/images/waterlink driver/Transit.png"),
        require("./assets/images/waterlink driver/order complete.png"),
        require("./assets/images/waterlink driver/submit amount.png"),
        require("./assets/images/waterlink driver/Profile.png")
      ],
      projectName: "Waterlink Driver",
      projectDesc:
        "Driver app for Waterlink\n\n<strong>Tech Stack:</strong> React Native, Redux (Saga), Google Maps API, React Native Elements, Firebase, NodeJS\n\n<strong>Features</strong>: Rider invitation and onboarding using SMS based Dynamic Links, In App wallet, Google Maps powered navigation system, Accept/Reject incoming live orders",
      descBullets: [
        "Laid foundation for the app's scaffolding in React Native",
        "Created an animatable bottom sheet component tailored to the project's needs by forking an npm package",
        "Devised an algorithm based off geohashing technique to allow effecient geoqueries on Cloud Firestore",
        "Designed and implemented continous background geo-polling feature to track driver's location in realtime utilizing android's foreground service"
      ],
      footerLink: [
        {
          name: "Download Mobile App",
          url: "https://play.google.com/store/apps/details?id=com.waterlinkdriver"
        }
      ]
    },
    {
      images: [
        require("./assets/images/procheck/dashboard.png"),
        require("./assets/images/procheck/daily targets.png"),
        require("./assets/images/procheck/downtime-log.png")
      ],
      projectName: "Procheck",
      projectDesc:
        "An IOT based machine performance monitoring and analytics SaaS\n\n<strong>Tech Stack:</strong> ReactJS, Redux (Saga), Power BI, Node JS, Firebase\n\n<strong>Features</strong>: Remote plant monitoring, Predictive maintenance through actionable insights, Machine downtime alerts and KPIs calculation, RBAC system with varied user permissions",
      descBullets: [
        "Built partner REST APIs for the platform on top of serverless Google Cloud Functions to allow customers query and consume platform data with custom built integrations",
        "Did UI/UX enhancements dashboard, KPI charts and downtime logs table for increased customer satisfaction",
        "Solved an edge case of non-consistent data being stored due to concurrent writes in a high throughput production line by intoducing consistency through transactional writes",
        "Created a data pipeline to feed a model in Power BI that shows embedded Power BI report for enterprise customers with in the application"
      ],
      footerLink: [
        {
          name: "Open Website",
          url: "https://procheck.pk/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications"),
  subtitle: "Achievements and Certifications collected yet",

  achievementsCards: [
    {
      title: "React (Hackerrank)",
      // subtitle:
      //   "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/certifications/react-hackerrank.png"),
      footerLink: [
        {
          name: "Open Certificate",
          url: "https://www.hackerrank.com/certificates/f12e173ef777"
        }
      ]
    },
    {
      title: "Problem Solving (Hackerrank)",
      // subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/certifications/problem-solving-basic-hackerrank.png"),
      footerLink: [
        {
          name: "Open Certificate",
          url: "https://www.hackerrank.com/certificates/12e9b8e73141"
        }
      ]
    },
    {
      title: "Javscript (Hackerrank)",
      // subtitle:
      //   "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/certifications/javascript-intermediate-hackerrank.png"),
      footerLink: [
        {
          name: "Open Certificate",
          url: "https://www.hackerrank.com/certificates/a17cd02f6539"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  display: false
};

// Talks Sections

const talkSection = {
  talks: [],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  // Please Provide with Your Podcast embeded Link
  podcast: [],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Want to have a chitchat or discuss a project or an opportunity? Feel free to contact via following",
  number: "+92 346 812 5687",
  email_address: "maazkhawar25@gmail.com",
  location: null, //"Karachi, Pakistan"
  openForOpportunities: true
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
