import React, {useCallback, useContext, useState} from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import Button from "../../components/button/Button";
import {useWindowDim} from "../../hooks/useWindowDim";

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  const isSmall = useWindowDim().width <= 928;
  const batchSize = isSmall ? 3 : 4;
  const [cursor, setCursor] = useState(batchSize);

  const onShowMore = useCallback(() => {
    setCursor(cursor => cursor + batchSize);
  }, [batchSize]);

  if (workExperiences.display) {
    return (
      <div id="experience">
        <div className="experience-container" id="workExperience">
          <div>
            <h1 className="experience-heading">Work Experience</h1>
            <div className="experience-cards-div">
              {workExperiences.experience.slice(0, cursor).map((card, i) => {
                return (
                  <ExperienceCard
                    key={i}
                    isDark={isDark}
                    cardInfo={{
                      company: card.company,
                      desc: card.desc,
                      date: card.date,
                      companylogo: card.companylogo,
                      role: card.role,
                      descBullets: card.descBullets
                    }}
                  />
                );
              })}
            </div>
            {cursor < workExperiences.experience.length ? (
              <Button
                onClick={onShowMore}
                text="Show More"
                className="project-button"
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return null;
}
