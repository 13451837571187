import {useState, useEffect} from "react";

export function useWindowDim(
  width = typeof window !== `undefined` && window.innerWidth,
  height = typeof window !== `undefined` && window.innerHeight
) {
  const [windowSize, setWindowSize] = useState({
    width,
    height
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
